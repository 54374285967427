import React from "react"
import { Link } from "gatsby"
import Search from "../search/search"
import { StaticImage, GatsbyImage } from "gatsby-plugin-image"

import { DropdownButton, Dropdown, ButtonGroup } from "react-bootstrap"

const HorizontalMenu = ({ dataSrc }) => {
  const caseStudyItem = dataSrc?.edges[0]

  console.log("caseStudyItem", caseStudyItem)
  return (
    <>
      <div className="hmenu-wrapper">
        <div className="container d-container">
          <div className="row justify-content-end align-items-center">
            {/* <DropdownButton
              id="services-dropdown-menu"
              title="services"
              className="hmenu-button mr-4 mr-lg-4 mr-md-3 mt-2 "
            >
              <div
                className="d-flex flex-column"
                style={{ width: "max-content" }}
              >
                <Link aria-label="link" className="dropdown-text" to="/tech-consulting/">
                  Technology Consulting
                </Link>
                <Link aria-label="link" className="dropdown-text" to="/quandary-build/">
                  Application Development
                </Link>
                <Link aria-label="link" className="dropdown-text" to="/quandary-connect/">
                  Integration Services
                </Link>
                <Link aria-label="link" className="dropdown-text" to="/procurement-automation-services/">
                  Procurement Automation
                </Link>
                <Link
                  aria-label="link"
                  to="/offline-application-development-construction-management/"
                >
                  Construction Management
                </Link>
                <Link aria-label="link" className="dropdown-text" to="/retail-automation/">
                  Retail Automation
                </Link>
                <Link aria-label="link" className="dropdown-text" to="/citizen-development-support/">
                  Citizen Development
                </Link>
              </div>
            </DropdownButton> */}

            {/* <ul className="nav-links" id="services-dropdown-menu">
            <li className="hmenu-button mr-4 mr-lg-4 mr-md-3 mt-2">
        <a href="#" className="desktop-item dropdown-toggle">Services</a>
  
        <div className="mega-box">
          <div className="content">

            <div className="row">
              <header>Consulting</header>
              <ul className="mega-links">
                <li> <Link aria-label="link" className="dropdown-text" to="/tech-consulting/">
                  Technology Consulting
                </Link></li>
               
              </ul>
            </div>
            <div className="row">
              <header>Automation</header>
              <ul className="mega-links">
                <li> <Link aria-label="link" className="dropdown-text" to="/procurement-automation-services/">
                  Procurement Automation
                </Link></li>
                <li> <Link aria-label="link" className="dropdown-text" to="/retail-automation/">
                  Retail Automation
                </Link></li>
                
              </ul>
            </div>
            <div className="row">
              <header>Development</header>
              <ul className="mega-links">
              <li> <Link aria-label="link" className="dropdown-text" to="/quandary-build/">
                  Application Development
                </Link></li>
                <li><Link aria-label="link" className="dropdown-text" to="/citizen-development-support/">
                  Citizen Development
                </Link></li>
              
              </ul>
            </div>
            <div className="row">
              <header>Management</header>
              <ul className="mega-links">
                <li><Link
                  aria-label="link"
                  to="/offline-application-development-construction-management/"
                >
                  Construction Management
                </Link></li>
             
              </ul>
            </div>
            <div className="row">
            <img
                        placeholder="blurred"
                        className="rounded d-flex w-100 img-fluid"
                        src="https://cdn.sanity.io/images/55lcecww/production/dceec435af07d66ca7a1aa259a5bdfc91ea427ff-1280x960.jpg"
                        alt="Application Development"
                        layout="constrained"
                        loading="lazy"
                        fetchpriority="low"
                      />
            </div>
          </div>
        </div>
      </li>
              </ul> */}
            <nav>
              <ul className="">
                <li className="dropdown">
                  <a href="#" className="nav-text">
                    Services<i className="fa fa-caret-down ml-2"></i>
                  </a>
                  <div className="mega-menu">
                    <div className="container">
                      <div className="item">
                        <h4>Consulting</h4>
                        <ul>
                          <li>
                            {" "}
                            <Link
                              aria-label="link"
                              className="dropdown-text"
                              to="/tech-consulting/"
                            >
                              Technology Consulting
                            </Link>
                          </li>
                          <li>
                            {" "}
                            <Link
                              aria-label="link"
                              className="dropdown-text"
                              to="/application-security-audit/"
                            >
                              Application Security Audit
                            </Link>
                          </li>
                          <li>
                            {" "}
                            <Link
                              aria-label="link"
                              className="dropdown-text"
                              to="/application-health-audit/"
                            >
                              Application Health Audit
                            </Link>
                          </li>

                          <li>
                            <Link
                              aria-label="link"
                              className="dropdown-text"
                              to="/procurement-automation-services/"
                            >
                              Procurement Automation
                            </Link>
                          </li>
                          <li>
                            <Link
                              aria-label="link"
                              className="dropdown-text"
                              to="/post-launch-enablement-services/"
                            >
                              Post Launch Enablement
                            </Link>
                          </li>
                        </ul>
                      </div>

                      <div className="item">
                        <h4>Development</h4>
                        <ul>
                          <li>
                            {" "}
                            <Link
                              aria-label="link"
                              className="dropdown-text"
                              to="/quandary-connect/"
                            >
                              Integration Services
                            </Link>
                          </li>
                          <li>
                            {" "}
                            <Link
                              aria-label="link"
                              className="dropdown-text"
                              to="/quandary-build/"
                            >
                              Application Development
                            </Link>
                          </li>
                          <li>
                            {" "}
                            <Link
                              aria-label="link"
                              className="dropdown-text"
                              to="/citizen-development-support/"
                            >
                              Citizen Development
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <div className="item">
                        <h4>Industry Focus</h4>
                        <ul>
                          <li>
                            {" "}
                            <Link
                              aria-label="link"
                              className="dropdown-text"
                              to="/offline-application-development-construction-management/"
                            >
                              Construction
                            </Link>
                          </li>

                          <li>
                            <Link
                              aria-label="link"
                              className="dropdown-text"
                              to="/financial-services-automation/"
                            >
                              Finance
                            </Link>
                          </li>

                          <li>
                            <Link
                              aria-label="link"
                              className="dropdown-text"
                              to="/patient-care-automation/"
                            >
                              Healthcare
                            </Link>
                          </li>
                          <li>
                            {" "}
                            <Link
                              aria-label="link"
                              className="dropdown-text"
                              to="/retail-automation/"
                            >
                              Retail
                            </Link>
                          </li>

                          <li>
                            <Link
                              aria-label="link"
                              className="dropdown-text"
                              to="/transportation-and-logistics/"
                            >
                              Transportation
                            </Link>
                          </li>
                        </ul>
                      </div>

                      <div className="item-casestudy pl-3">
                        {/* <h3>Custom</h3> */}
                        <div className="row">
                          <div className="p-relative">
                            <div className="case-study-card">
                              {/* <StaticImage
                                placeholder="blurred"
                                className="d-flex w-100 img-fluid"
                                src={caseStudyItem?.mainImage}
                                layout="constrained"
                                style={{ height: "10rem" }}
                                loading="lazy"
                                fetchpriority="low"
                              /> */}
                              {caseStudyItem?.node?.mainImage && (
                                <GatsbyImage
                                  image={
                                    caseStudyItem?.node?.mainImage !== null
                                      ? caseStudyItem?.node?.mainImage.asset
                                          .gatsbyImageData
                                      : null
                                  }
                                  className="d-flex w-100 img-fluid"
                                  alt={caseStudyItem?.node?.title}
                                  style={{ height: "13rem" }}
                                  layout="constrained"
                                  placeholder="blurred"
                                />
                              )}

                              <div className="card-body">
                                {caseStudyItem?.node?.title && (
                                  <small className="text-white font-bold">
                                    {caseStudyItem?.node?.title}
                                  </small>
                                )}

                                <div className="card-link">
                                  {caseStudyItem?.node?.slug?.current && (
                                    <Link
                                      aria-label="link"
                                      to={`/case-study/${caseStudyItem?.node?.slug?.current}`}
                                    >
                                      Read Case Study
                                    </Link>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </nav>
            <DropdownButton
              id="products-dropdown-menu"
              title="products"
              className="hmenu-button mr-1 "
            >
              <div className="d-flex flex-column">
                <Dropdown
                  as={ButtonGroup}
                  drop="right"
                  className="peakSuite-dropdown"
                >
                  <Link aria-label="link" to="/peaksuite/">
                    peaksuite
                  </Link>

                  <Dropdown.Toggle
                    split
                    variant="success"
                    id="dropdown-split-basic"
                  />

                  <Dropdown.Menu className="peakSuite-dropdown-menu">
                    <div className="d-flex flex-column">
                      {/* <Link aria-label="link" to="/peaksuite/peaksuite-echo/">
                        echo
                      </Link> */}
                      {/* <Link
                        aria-label="link"
                        to="/peaksuite/peaksuite-trailhead/"
                      >
                        trailhead
                      </Link> */}
                      <Link
                        aria-label="link"
                        to="/peaksuite/peaksuite-compass/"
                      >
                        compass
                      </Link>
                      {/* <Link
                        aria-label="link"
                        to="/peaksuite/peaksuite-basecamp/"
                      >
                        basecamp
                      </Link> */}
                      <Link
                        aria-label="link"
                        to="/peaksuite/peaksuite-docgenerator/"
                      >
                        document generator
                      </Link>
                      {/* <Link
                        aria-label="link"
                        to="/peaksuite/peaksuite-pivot-table/"
                      >
                        Pivot Table
                      </Link> */}
                      {/* <Link
                        aria-label="link"
                        to="/peaksuite/peaksuite-chat-tool/"
                      >
                        Chat
                      </Link> */}
                      <Link
                        aria-label="link"
                        to="/peaksuite/peaksuite-multi-file-uploader/"
                      >
                        Multi-File Uploader
                      </Link>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
                <Link aria-label="link" to="/quick-base-solutions/">
                  Quickbase
                </Link>
                <Link aria-label="link" to="/workato-solutions/">
                  Workato
                </Link>
                <Link aria-label="link" to="/pipefy/">
                  Pipefy
                </Link>
              </div>
            </DropdownButton>
            <DropdownButton
              id="resources-dropdown-menu"
              title="resources"
              className="hmenu-button mr-1 "
            >
              <div
                className="d-flex flex-column"
                style={{ width: "max-content" }}
              >
                <Link aria-label="link" to="/knowledge-base/">
                  knowledge base
                </Link>
                <Link aria-label="link" to="/news/">
                  Blog Entries
                </Link>
                <Link aria-label="link" to="/case-studies/">
                  case studies
                </Link>
                <Link aria-label="link" to="/faq/">
                  faq
                </Link>
              </div>
            </DropdownButton>
            <DropdownButton
              id="us-dropdown-menu"
              title="who are we"
              className="hmenu-button mr-4 mr-xl-5 mr-md-3"
            >
              <div
                className="d-flex flex-column"
                style={{ width: "max-content" }}
              >
                <Link aria-label="link" to="/about-us/">
                  about us
                </Link>
                <Link aria-label="link" to="/our-approach/">
                  our approach
                </Link>
                <Link aria-label="link" to="/careers/">
                  careers
                </Link>
                <Link aria-label="link" to="/contact/">
                  contact us
                </Link>
              </div>
            </DropdownButton>
            <div className="searchBar">
              <div className="row">
                <Search />
                <Link
                  aria-label="link"
                  id="nav-contact-link-search"
                  to="/contact/"
                >
                  Let's Chat
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default HorizontalMenu
