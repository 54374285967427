import React from "react"

class searchAll extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: "",
      show: false,
      location: "",
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }
  handleChange(event) {
    this.setState({ value: event.target.value })
  }

  handleSubmit(event) {
    if (this.state.value !== "") {
      window.location.href = `/search/?=${this.state.value}`
      event.preventDefault()
    } else {
      alert("Search field is empty")
      event.preventDefault()
    }
  }
  render() {
    return (
      <div className="searchbar-all mr-2 d-flex align-items-center">
        <form onSubmit={this.handleSubmit}>
          <div className="searchbarbox">
            <div className="row">
              <div className="col-2 col-md-12 ml-auto px-1 px-md-3">
                <input
                  className="form-control"
                  placeholder="Search"
                  value={this.state.value}
                  onChange={this.handleChange}
                  id="search-id"
                />
              </div>
              {/* <div className="col-2 col-md-2 mr-auto px-1 px-md-3">
                <label htmlFor="search-id" className="d-none">
                  Search Id
                </label>
                <button
                  aria-label="btn"
                  className="btn btn-search"
                  type="submit"
                >
                  <i className="fa fa-search" aria-hidden="true" />
                </button>
              </div> */}
            </div>
          </div>
        </form>
      </div>
    )
  }
}

export default searchAll
